import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import LogosStrip from '@solid-ui-components/LogosStrip'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalPortfolio from '@solid-ui-blocks/Modal/Block03'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Content from '@solid-ui-blocks/Content/Block01'
import Footer from '@solid-ui-blocks/Footer/Block02'
import Team from '@solid-ui-blocks/Teams/Block02'
import ServicesGrid from '@solid-ui-blocks/Features/Block05'
import WhyChooseUs from '@solid-ui-blocks/Features/Block01'
import Clients from '@solid-ui-blocks/FeaturesWithPhoto/Block05'
import Programs from '@solid-ui-blocks/Features/Block01'
import ContentMap from '@solid-ui-components/ContentMap'

import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'

const MotorVehicleAccidents = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)
  const heroData = props.data.allBlockContent.nodes.find(
    element => element.identifier === 'hero'
  )
  let thumbnail = heroData.images[0].src.childImageSharp.gatsbyImageData

  return (
    <Layout {...props}>
      <Seo
        title='Motor Vehicle Accidents'
        indexed={true}
        thumbnail={thumbnail}
        keywords={[]}
      />
      {/* Modals */}
      {/* <ModalWithTabs content={content['authentication']} reverse /> */}
      <ModalWithTabs content={content['contact']} />
      <ModalPortfolio content={content['dr-demetrios-john-giokaris']} />
      <ModalPortfolio content={content['dr-krishna-c-chunduri']} />
      <ModalPortfolio content={content['ruben-bermudez-pa']} />
      {/* <ModalPortfolio content={content['dr-darrel-saldanha']} /> */}
      {/* Blocks */}
      <Header content={content['header']} />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} reverse />
      </Container>
      <Container variant='full' sx={styles.insuranceContainer}>
        <LogosStrip content={content['insurance']} />
      </Container>
      <Divider space='4' />
      <Content content={content['content-one']} />
      <Divider space='4' />
      <Content content={content['content-two']} />
      <Divider space='4' />
      <Container variant='full' sx={styles.programs}>
        <Programs
          content={content['neighborhood']}
          wrapperStyles={styles.programsWrapper}
        />
      </Container>
      <Divider space='4' />
      {/* <ServicesGrid content={content['content-three']} />
      <Divider space='4' /> */}
      {/* <Container variant='full' sx={styles.whyChooseUsContainer}>
        <WhyChooseUs content={content['why-choose-us']} />
      </Container>
      <Divider space='4' /> */}
      <Team content={content['physicians']} />
      <Divider space='4' />
      <Divider space='4' />
      <div style={{ width: `100%`, height: `600px` }}>
        <ContentMap
          content={{
            lat: 42.0985394,
            lng: -87.9600665,
            zoom: 14
          }}
        />
      </div>
      {/* <Divider space='4' />
      <Container variant='full' sx={styles.contentTwoContainer}>
        <Content content={content['content-two']} />
      </Container> */}
      <Footer content={content['footer2']} />
    </Layout>
  )
}

export const query = graphql`
  query servicesRandMotorVehicleAccidentsBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/motor-vehicle-accidents", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default MotorVehicleAccidents
